import React from 'react'
import { Heading, Box, Container } from 'theme-ui'
import Layout from '../components/layout'
import { ShopifyHtml } from '../components/ShopifyHtml'
// import NewsletterForm from '../components/NewsletterForm'
import Exchange from '../components/Exchange'
// import ConsultationForm from '../components/BookConsultationForm'

const PageView = ({ title, summary, body, currentPath = '' }) => (
  <Layout title={title} description={summary}>
    <Container
      as="main"
      pb={8}
      sx={{ maxWidth: 680, h1: { textAlign: 'center' } }}
    >
      <Box pt={7} pb={2}>
        <Heading as="h1">{title}</Heading>
      </Box>
      <ShopifyHtml
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
      {currentPath.includes('exchange-form') && <Exchange variant="primary" />}
    </Container>
  </Layout>
)

export default PageView
